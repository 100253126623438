<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row class="mx-sm-8 mx-2">
        <v-col cols="12">
          <h1 class="mt-10">Nuevo Formato</h1>
          <hr class="">
        </v-col>

        <v-col cols="12" lg="6" class="mt-10">
          <v-text-field
              label="Titulo"
              placeholder="Titulo"
              v-model="data.name"
              outlined
              :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="6" class="mt-10">
          <v-text-field
              label="Orden"
              v-model="data.order"
              placeholder="Orden"
              outlined
              type="number"
              :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="12" class="mt-4  ">
          <div class="bg-radio-button d-flex justify-center" style="width: 100%">
            <v-radio-group v-model="data.isField" row class="justify-space-between">
              <span class="mr-4">Subida de archivo historico</span>
              <v-radio :value="true"></v-radio>
              <span class="mr-4">Layout que se debe de llenar</span>
              <v-radio :value=false></v-radio>
            </v-radio-group>
          </div>
        </v-col>


        <v-col cols="12" v-if="!data.isField">
          <h1 class="mt-4 mx-2 ">Layout</h1>
          <trumbowyg
              v-model="data.content"
              name="body"
              :config="configs.advanced"
              placeholder="Enter your content here"
              class="form-control"
          ></trumbowyg>
          <p v-if="errorContent" style="color: red">Campo requerido</p>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary" type="submit" style="width: 200px">
            Guardar
          </v-btn>
        </v-col>

      </v-row>
    </v-form>
  </div>

</template>

<script>

import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import {mapActions} from "vuex";
import Overlay from "@/components/Overlay.vue";

export default {
  name: "CreateDocuments",
  data() {
    return {
      overlay: false,
      errorContent: false,
      dataType: false,
      data: {
        name: null,
        order: null,
        content: '',
        parentId: null,
        isField: false,
      },
      rules: {
        required: value => !!value || 'Este campo es requerido.',
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };

  },
  components: {
    Overlay,
    Trumbowyg
  },
  watch: {
    'data.order'(newValue) {
      if (newValue !== null) {
        this.data.order = parseInt(newValue, 10);
      }
    },
  },
  methods: {
    ...mapActions('formats', ['createFormats']),
    async submitForm() {
      if (this.$refs.form.validate()) {

        this.errorContent = false;
        this.overlay = true;
        const response = await this.createFormats(this.data);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          this.overlay = false;
          await this.$router.push('/layouts_lista')
        } else {
          this.overlay = false;
          this.sweetAlertError(response.error)
        }
      }
    },
  }

}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
